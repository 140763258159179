<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { linear } from "svelte/easing";

  export let images;
  export let html;
  export let autoSlide;

  let wrapperElem;
  let width;

  let direction = 1;
  let index = 0;
  let intervalID;

  function next(e) {
    e.preventDefault();
    direction = 1;
    index = (index + 1) % images.length;
  }

  function prev(e) {
    e.preventDefault();
    direction = -1;
    index = (images.length + index - 1) % images.length;
  }

  function flyOut(
    node,
    {
      delay = 0,
      duration = 400,
      easing: easing$1 = linear,
      x = 0,
      y = 0,
      opacity = 0,
    }
  ) {
    x = width * direction * -1;

    const style = getComputedStyle(node);
    const target_opacity = +style.opacity;
    const transform = style.transform === "none" ? "" : style.transform;
    const od = target_opacity;

    return {
      delay,
      duration,
      easing: easing$1,
      css: (t, u) => `
                    transform: ${transform} translate(${(1 - t) * x}px, ${
        (1 - t) * y
      }px);
                    opacity: ${target_opacity}`,
    };
  }

  function flyIn(
    node,
    {
      delay = 0,
      duration = 400,
      easing: easing$1 = linear,
      x = 0,
      y = 0,
      opacity = 0,
    }
  ) {
    x = width * direction;

    const style = getComputedStyle(node);
    const target_opacity = +style.opacity;
    const transform = style.transform === "none" ? "" : style.transform;
    const od = target_opacity;

    return {
      delay,
      duration,
      easing: easing$1,
      css: (t, u) => `
                    transform: ${transform} translate(${(1 - t) * x}px, ${
        (1 - t) * y
      }px);
                    opacity: ${target_opacity}`,
    };
  }

  function startSliding(e) {
    intervalID = setInterval(function () {
      index = (images.length + index - 1) % images.length;
    }, autoSlide);
  }

  function stopSliding(e) {
    clearInterval(intervalID);
  }

  function handleResize(e) {
    updateWidth();
  }

  function updateWidth() {
    width = wrapperElem.getBoundingClientRect().width;
  }

  if (autoSlide !== false) {
    onMount(startSliding);
  }

  onMount(() => {
    updateWidth();
  });
</script>

<svelte:window on:resize={handleResize} />

<div
  class="sitebanner_carousel"
  bind:this={wrapperElem}
  on:mouseenter={stopSliding}
  on:mouseleave={startSliding}>
  <div class="items">
    {#each [images[index]] as item (index)}
      {#if item}
        <div class="item">
          {#if item.Url}
            <div
              in:flyIn={{ duration: 500 }}
              out:flyOut={{ duration: 500 }}
              class="item__image"
              style="background-image: url('{item.Url}');" />
          {/if}
        </div>
      {/if}
    {/each}
  </div>

  {#if images.length > 1}
  <a class="prev" href="#prev" on:click={prev}>
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
    </svg>
  </a>

  <a class="next" href="#next" on:click={next}>
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
    </svg>
  </a>
  {/if}
  
  <div class="sitebanner_html">
  	
  		{@html html}
  	
  </div>
  
</div>
