import Carousel from './components/Carousel.svelte';

// Wait for combocarousel_renderer to finish depositing our data
document.addEventListener('DOMContentLoaded', () => {

	

	let containers = document.getElementsByClassName('site_banner')

	if (containers) {
		
		Array.prototype.forEach.call(containers, container => {

			window.M.theme_soon = window.M.theme_soon || {};
			window.M.theme_soon.sitebannerimages = window.M.theme_soon.sitebannerimages || [];
			window.M.theme_soon.sitebannerhtml = window.M.theme_soon.sitebannerhtml || null;
			
			let { sitebannerimages: images, sitebannerhtml: html } = window.M.theme_soon

			console.debug('images --> ', images)
			console.debug('html --> ', html)
			console.debug('container --> ', container)
			
			new Carousel ({
				target: container,
				props: {
					images,	html,
					autoSlide: 5000
				}
			});
		})		
	}
});
